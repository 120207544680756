const onSubmitForm = function(event) {
	if(event?.target?.form?.checkValidity()) {
		event.preventDefault();

		document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
		event.target.form.querySelector('.form-loader').style.display = 'flex';

		const recaptchaIds = Array.from(document.querySelectorAll('.g-recaptcha')).map(el => el.id)
		const widgetId = recaptchaIds.findIndex(recaptchaId => recaptchaId === event.target.form.querySelector('.g-recaptcha').id);
		grecaptcha.reset(widgetId);
		grecaptcha.execute(widgetId);
	}
}

window.onSubmitForm = onSubmitForm;
